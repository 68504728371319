import React from "react"
import styled from "styled-components"

import { Device } from "./theme"

import PersonIcon from "./../../static/images/person.svg"
import TeamIcon from "./../../static/images/team.svg"

const CardWrapper = styled.div`
    background: ${({ theme }) => theme.colors.White};
    border-radius: 8px;
    padding: 12px;
    max-width: 500px;
    box-shadow: 4px 4px 80px #00000066;

    @media ${Device.mobile} {
        margin: 2rem;
    }
`

const CardHeader = styled.div`
    display: grid;
    justify-items: center;
    padding-top: 1rem;
`

const CardPricing = styled.div`
    display: grid;
    justify-items: center;
`

const CardBody = styled.div`
    display: grid;
    padding: 1rem;
`

const Title = styled.h1`
    color: ${({ theme }) => theme.colors.DarkBlue};
    margin: 0;
    margin-top: 0.5rem;
`

const Price = styled.h1`
    color: ${({ theme }) => theme.colors.LightBlue};
    font-size: 3rem;
`

const Divider = styled.hr`
    background: ${({ theme }) => theme.colors.LightGray2};
    height: 1px;
    border: 0px;
`

const PricingCard = ({ icon, title, price, children, disabled }) => {
    return (
        <CardWrapper style={disabled && { opacity: 0.6 }}>
            <CardHeader>
                <img src={icon === "person" ? PersonIcon : TeamIcon} height="36" alt="pricing icon" />
                <Title>{title}</Title>
            </CardHeader>
            <Divider />
            <CardPricing>
                <Price>{price}</Price>
            </CardPricing>
            <Divider />
            <CardBody>
                {children}
            </CardBody>
        </CardWrapper>
    )
}

export default PricingCard