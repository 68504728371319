import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { Device } from "./theme"

import FeatureList from "./featureList"
import Safe from "./../../static/images/safe.svg"

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(8,1fr);
    grid-template-rows: 1fr 1fr;

    @media ${Device.mobile} {
        grid-template-columns: 1fr;
    }
`

const BlueRow = styled.div`
    color: ${props => props.theme.colors.White};
    background: rgb(0,104,162);
    background: linear-gradient(90deg, rgba(0,104,162,1) 50%, rgba(0,144,223,1) 100%);
    display: grid;
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 9;
    grid-template-columns: repeat(8,1fr);
    grid-template-rows: 1fr;
    align-items: flex-end;
    padding-bottom: 48px;

    @media ${Device.mobile} {
        grid-column: 1;
        grid-template-columns: 1fr;
    }
`

const WhiteRow = styled.div`
    grid-row: 2;
    grid-column-start: 1;
    grid-column-end: 9;
    display: grid;
    grid-template-columns: repeat(8,1fr);
    grid-template-rows: 1fr;
    padding-top: 48px;
    padding-bottom: 72px;

    @media ${Device.mobile} {
        grid-column: 1;
        grid-template-columns: 1fr;
    }
`

const FeatureListContainer = styled.div`
    grid-column-start: 3;
    grid-column-end: 6;

    @media ${Device.mobile} {
        grid-column: 1;
        padding: 0 1rem;
    }
`

const ImageContainer = styled.div`
    display: grid;
    position: relative;
    z-index: 10;
    grid-column-start: 5;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 3;
    align-self: center;

    @media ${Device.mobile} {
        display: none;
    }
`

const FeatureListHeading = styled.h1`
    margin-top: 0;
`

const data = [
    {
        heading: "Stundenzettel ade!",
        bulletPoints: [
            "Zeiten erfassen",
            "Projekte verwalten",
            "Offline nutzen"
        ]
    },
    {
        heading: "Blitzschnelle Auswertung",
        bulletPoints: [
            "PDF & CSV Export",
            "Dokumentationspflicht konform",
            "DSGVO konform"
        ]
    }
]

const DescriptionSection = () => {
    const imageData = useStaticQuery(graphql`
    query {
      zettl: file(relativePath: { eq: "zettl_ui.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Grid>
            <BlueRow>
                <FeatureListContainer>
                    <div>
                        <FeatureListHeading>{data[0].heading}</FeatureListHeading>
                        <FeatureList bulletPoints={data[0].bulletPoints} />
                    </div>
                </FeatureListContainer>
            </BlueRow>
            <WhiteRow>
                <FeatureListContainer>
                    <div>
                        <FeatureListHeading>{data[1].heading}</FeatureListHeading>
                        <FeatureList bulletPoints={data[1].bulletPoints} bulletType="blue" />
                    </div>
                </FeatureListContainer>
            </WhiteRow>
            <ImageContainer>
                <Img imgStyle={{ objectFit: 'contain' }} style={{ height: '90vh' }} fluid={imageData.zettl.childImageSharp.fluid} />
                <img height="60" style={{ position: 'absolute', bottom: 24, justifySelf: "center" }} src={Safe} alt="DSGVO & GOBD" />
            </ImageContainer>
        </Grid>
    )
}

export default DescriptionSection
