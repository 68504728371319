import React from "react"
import styled from "styled-components"

import Layout from "./../components/layout"
import HeaderFeature from "../components/featureGrid"
import NavGrid from "../components/navGrid"
import DescriptionSection from "../components/descriptionSection"
import PricingSection from "../components/pricingSection"
import AppStoreSection from "../components/appStoreSection"

const HeaderContainer = styled.div`
  display: grid;
  grid-template-rows: 85px auto;
  height: 90vh;
  background-color: ${props => props.theme.colors.LightGray};
  overflow: hidden;
`

export default function Home() {
  return (
    <Layout>
      <HeaderContainer>
        <NavGrid />
        <HeaderFeature />
      </HeaderContainer>
      <DescriptionSection />
      <PricingSection />
      <AppStoreSection />
    </Layout>
  )
}
