import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Device } from "./theme"

import AppStorePath from "./../../static/images/appStore.svg"
import GooglePlay from "./../../static/images/googlePlay.svg"

const AppStoreSectionContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(0,104,162);
    background: ${({ theme }) => theme.colors.LightGray};
    padding: 6rem 0;
    grid-gap: 3rem;

    @media ${Device.mobile} {
        grid-template-columns: 1fr;
        padding: 3rem 0;
    }
`

const AppStoreContainer = styled.div`
    display: grid;
    grid-column: 4;
    justify-items: center;

    @media ${Device.mobile} {
        grid-column: 1;
        grid-row: 1;
    }
`

const PlayStoreContainer = styled.div`
    display: grid;
    grid-column: 5;
    justify-items: center;

    @media ${Device.mobile} {
        grid-column: 1;
        grid-row: 2;
    }
`

const AppStoreSection = () => {

    return (
        <AppStoreSectionContainer id="download">
            <AppStoreContainer>
                <Link target="blank" to={"https://apps.apple.com/de/app/zettl-die-zeiterfassung/id1528015686"}>
                    <img height="60px" src={AppStorePath} alt="Apple AppStore" />
                </Link>
            </AppStoreContainer>
            {/* <PlayStoreContainer>
                <Link to={"#"}>
                    <img height="60px" src={GooglePlay} alt="Google PlayStore" />
                </Link>
            </PlayStoreContainer> */}
        </AppStoreSectionContainer>
    )
}

export default AppStoreSection