import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import { ButtonStyle } from "./button"
import StopWatchPath from "./../../static/images/stopwatch.svg"
import { Device } from './theme';

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  height: 100%;
  width: 100%;
`

const ImageContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row: 1;
  align-self: center;

  @media ${Device.mobile} {
    display: none;
  }
`

const FeatureContainer = styled.div`
  align-self: center;
  grid-column-end: 5;
  grid-column-start: 3;
  grid-row: 1;
  margin-left: 6rem;

  @media ${Device.mobile} {
    grid-column-start: 1;
    grid-column-end: 6;
    margin-left: 4rem;
  }
`

const FeatureHeading = styled.h1`
  position: relative;
  color: ${props => props.theme.colors.DarkBlue};
  font-weight: 700;
  font-size: 6rem;
  line-height: 5.5rem;
  font-style: italic;
  margin: 0;

  @media ${Device.tablet} {
    font-size: 3rem;
    line-height: 2.5rem;
  }

  @media ${Device.mobile} {
    font-size: 2rem;
    line-height: 2rem;
  }
`

const FeatureText = styled.p`
  margin: 1.5rem 0;
  font-size: 2.5rem;

  @media ${Device.tablet} {
    font-size: 1.5rem;
    margin: 1rem 0;
  }

  @media ${Device.mobile} {
    font-size: 1.5rem;
    margin: 1rem 0;
  }
`

const StopWatch = styled.img`
  position: absolute;
  left: -104px;
  top: 0px;
  max-height: 84px;

  @media ${Device.tablet} {
    max-height: 48px;
    left: -60px;
    top: -8px;
  }

  @media ${Device.mobile} {
    max-height: 30px;
    left: -36px;
    top: -2px;
  }
`

const HeaderFeature = () => {
  const data = useStaticQuery(graphql`
    query {
      start: file(relativePath: { eq: "zettl-start.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <FeatureGrid>
      <ImageContainer>
        <Img imgStyle={{ objectFit: 'contain' }} style={{ height: 'calc(90vh - 85px - 4rem)' }} fluid={data.start.childImageSharp.fluid} />
      </ImageContainer>
      <FeatureContainer>
        <FeatureHeading>
          <StopWatch src={StopWatchPath} alt="Zeiterfassung einfach" />
          Zeiterfassung
          </FeatureHeading>
        <FeatureText>
          Einfach, schnell und <br />
          überall dabei.
          </FeatureText>
        <ButtonStyle>
          <a href="#download">Jetzt starten</a>
        </ButtonStyle>
      </FeatureContainer>
    </FeatureGrid>
  )
}

export default HeaderFeature