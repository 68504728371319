import React from "react"
import styled from "styled-components"

import FeatureList from "./featureList"
import PricingCard from "./pricingCard"
import Button from "./button"
import { Device } from "./theme"

const PricingContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    background: rgb(0,104,162);
    background: linear-gradient(90deg, rgba(0,104,162,1) 50%, rgba(0,144,223,1) 100%);
    padding: 8rem 0;
    grid-gap: 3rem;

    @media ${Device.mobile} {
        grid-template-columns: 1fr;
        padding: 6rem 0;
        grid-gap: 3rem;
    }
`

const Card1 = styled.div`
    grid-column-start: 3;
    grid-column-end: 5;

    @media ${Device.tablet} {
        grid-column-start: 2;
        grid-column-end: 5;
    }

    @media ${Device.mobile} {
        grid-column: 1;
        grid-row: 1;
    }
`

const Card2 = styled.div`
    grid-column-start: 5;
    grid-column-end: 7;

    @media ${Device.tablet} {
        grid-column-start: 5;
        grid-column-end: 8;
    }

    @media ${Device.mobile} {
        grid-column: 1;
        grid-row: 2;
    }
`

const GratisButton = styled(Button)`
    margin-top: 2.5rem;
    justify-self: center;
`

const cardData = [
    {
        icon: "person",
        title: "Einzelperson",
        pricing: "0 €",
        bulletPoints: [
            "Zeiten erfasssen",
            "Projekte verwalten",
            "Zeiten exportieren",
            "Mobile Apps"
        ]
    },
    {
        icon: "team",
        title: "Teams",
        pricing: "soon...",
        bulletPoints: [
            "Zeiten erfasssen",
            "Projekte verwalten",
            "Zeiten exportieren",
            "Mobile Apps"
        ],
        additionalBullets: [
            "Teammitglieder verwalten",
            "Gruppenauswertung",
            "eigenes Logo im Export"
        ]
    }
]

const PricingSection = () => {

    const cardData1 = cardData[0];
    const cardData2 = cardData[1];

    return (
        <PricingContainer>
            <Card1>
                <PricingCard icon={cardData1.icon} title={cardData1.title} price={cardData1.pricing}>
                    <FeatureList bulletType="gray" bulletPoints={cardData1.bulletPoints} />
                </PricingCard>
            </Card1>
            <Card2>
                <PricingCard disabled icon={cardData2.icon} title={cardData2.title} price={cardData2.pricing}>
                    <FeatureList bulletType="gray" bulletPoints={cardData2.bulletPoints} />
                    <FeatureList bulletType="gray" bulletPoints={cardData2.additionalBullets} fontType="bold" />
                    {/* <GratisButton link={"/Kontakt"} text="Betatester werden" /> */}
                </PricingCard>
            </Card2>
        </PricingContainer>
    )
}

export default PricingSection