import React from "react"
import styled from "styled-components"

import BulletBlue from "./../../static/images/bulletBlue.svg";
import BulletWhite from "./../../static/images/bulletWhite.svg";
import BulletGray from "./../../static/images/bulletGray.svg";

const handleBulletType = bullet => {
    switch (bullet) {
        case "blue":
            return BulletBlue;
        case "white":
            return BulletWhite;
        case "gray":
            return BulletGray;
        default:
            return BulletWhite;
    }
};

const ListItem = styled.li`
    position: relative;
    font-size: 1.2rem;
    border-radius: 0;
    font-weight: ${({ fontType }) => fontType === "bold" ? 700 : 300};
    margin-bottom: 0.5rem;

    &:before {
        content: "";
        position: absolute;
        left: -28px;
        top: 6px;
        width: 14px;
        height: 14px;
        background: url(${({ bulletType }) => handleBulletType(bulletType)});
        background-repeat: no-repeat;
        background-size: 14px 14px;
    }
`

const List = styled.ul`
    margin: 0;
    list-style: none;
`

const FeatureList = ({ bulletPoints, bulletType, fontType }) => {
    return (
        <List>
            {
                bulletPoints && bulletPoints.map((bulletPoint, index) => <ListItem key={"list-item-" + index} bulletType={bulletType} fontType={fontType}>{bulletPoint}</ListItem>)
            }
        </List>
    )
}

export default FeatureList